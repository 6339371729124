import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import stepsReducer from './reducers/stepReducer';
import resultReducer from './reducers/resultReducer';

const rootReducer = combineReducers({
    resultReducer: resultReducer,
    stepReducer: stepsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;