import React from 'react';
import { Form, Input, Button, Typography } from 'antd';

const { Title } = Typography;

const EmailForm = () => {
    const onFinish = (values) => {
        // Aqui você pode fazer algo com o e-mail, como enviar uma requisição para a API
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <Title level={2} style={{ textAlign: 'center' }}>Enviar análise completa por email</Title>
            <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ maxWidth: '600px', margin: '0 auto' }} // Adicione estilos CSS aqui
            >
                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%', fontSize: '1.5em', padding: '10px 0' }}>
                        Enviar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EmailForm;