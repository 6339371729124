import React, { useEffect, useState } from 'react';
import {SolutionOutlined} from '@ant-design/icons';
import { Steps, Typography } from 'antd';
import { connect } from 'react-redux';
import { fetchSteps, fetchResult } from '../actions/stepActions'
import './StepsAnalise.css';
import { CheckCircleOutlined, LoadingOutlined, ClockCircleOutlined } from '@ant-design/icons';
import EmailForm from './EmailForm';
import AnaliseResult from './AnaliseResult';

const { Title } = Typography;

const statusIconMap = {
    'completed': { icon: <CheckCircleOutlined style={{ fontSize: '24px', color: 'green' }} />, color: 'green' },
    'process': { icon: <LoadingOutlined style={{ fontSize: '24px', color: 'blue' }} />, color: 'blue' },
    'wait': { icon: <ClockCircleOutlined style={{ fontSize: '24px', color: 'red' }} />, color: 'red' },
};

const StepsAnalise = ({ steps, fetchSteps, fetchResult, taskId, uploadSuccess }) => {
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [showAnaliseResult, setShowAnaliseResult] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchAndCheckSteps = async () => {
            if (taskId) {
                await fetchSteps(taskId);
            }
        };

        fetchAndCheckSteps();
    }, [fetchSteps, taskId]);

    useEffect(() => {
        const allStepsCompleted = steps.every(step => step.status === 'completed');
        if (allStepsCompleted && uploadSuccess) {
            fetchResult(taskId);
            setShowAnaliseResult(true);
        }else {
            setShowAnaliseResult(false);
        }
        if (allStepsCompleted) {
            setTimeout(() =>  5000); // Mostra o formulário de e-mail 5 segundos após a análise ser concluída
        } else {
            const fetchAndCheckSteps = async () => {
                await fetchSteps(taskId);
            };

            const id = setTimeout(fetchAndCheckSteps, 5000);
            return () => clearTimeout(id);
        }
    }, [steps, fetchSteps, fetchResult]);

    if (!steps) {
        console.log("steps", steps) // Adicione este console.log
        return null; // ou seu componente de carregamento
    }

    return (
        console.log("Aqui", steps),
            <div>
                <Steps
                    className="steps-container"
                    items={steps.map(step => ({
                        title: step.title,
                        status: step.status,
                        icon: statusIconMap[step.status].icon,
                    }))}
                />
                {showAnaliseResult && <AnaliseResult />}

            </div>
    );
};

const mapStateToProps = state => {
    return { steps: state.stepReducer };
};
export default connect(mapStateToProps, { fetchSteps, fetchResult })(StepsAnalise);