import axios from 'axios';

export const fetchSteps = (id) => async dispatch => {
    const response = await axios.get(`https://ui8zfm3ez7.execute-api.us-east-1.amazonaws.com/dev/essay/status/${id}`);
    dispatch({ type: 'FETCH_STEPS', payload: response.data });
};

export const fetchResult = (id) => async dispatch => {
    const response = await axios.get(`https://ui8zfm3ez7.execute-api.us-east-1.amazonaws.com/dev/get-result-analysis/${id}`);
    dispatch({ type: 'FETCH_RESULT', payload: response.data });
}