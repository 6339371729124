import React, { useState } from 'react';
import { Card, Space, Button, Badge } from 'antd';
import { TypeAnimation } from 'react-type-animation';
import { connect} from "react-redux";
import './AnaliseResult.css';


const Modal = ({ onClose }) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}>
            <div style={{
                borderRadius: '5px',
                position: 'relative' // para o botão de fechar
            }}>
                <button onClick={onClose} style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    fontSize: '20px'
                }}>X</button>
                <iframe src="https://forms.monday.com/forms/embed/033cacf8a8a51cd5b3dce05de09c83b4?r=use1" width="650" height="800" style={{ border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)", borderRadius: 15 }}></iframe>
            </div>
        </div>
    );
};

const AnaliseResult = ({ result }) => {

    console.log("Resultado", result);

    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    if (!result) {
        return null;
    }

    const formattedResult = result
        ? decodeURIComponent(result.replace(/\\u/g, '%')).split('\n')
        : [];

    const sequence = [
        'Olá, tudo bem? Aqui está o resultado da análise da sua redação:\n',
        ...formattedResult
    ].join('\n');

    // Extrai a nota do texto
    const noteRegex = /(\d+\/\d+)/;
    const noteMatch = sequence.match(noteRegex);
    const note = noteMatch ? noteMatch[0] : null;

    const noteValue = note ? parseInt(note.split('/')[0]) : 0;
    const badgeColor = noteValue < 500 ? 'red' : '#2E8B57';

    console.log("Resultado Formatado", formattedResult);
    return (
        <Space direction="vertical" size={16} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Card
                className="card-container"
                title="Resultado da Análise da Redação"
                extra={note && (
                    <Badge
                        count={note}
                        style={{
                            backgroundColor: badgeColor, // Use a cor do crachá aqui
                            color: 'white',
                            boxShadow: '0 0 0 1px #d9d9d9 inset',
                            fontSize: '1.2em',
                            padding: '0 10px',
                            fontWeight: 'bold',
                        }}
                    />
                )}
                style={{
                    width: '100%', // Altere para 100%
                    margin: '50px auto 50px', // Adicione margem ao topo
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                    transition: '0.3s',
                    borderRadius: '5px',
                    backgroundColor: '#f8f9fa',
                    color: '#343a40',
                    ':hover': {
                        backgroundColor: '#343a40',
                        color: '#f8f9fa',
                    },
                }}
            >
                <TypeAnimation
                    splitter={(str) => str.split(/(?= )/)}
                    sequence={[
                        6000,
                        sequence
                    ]}
                    speed={{ type: 'keyStrokeDelayInMs', value: 90 }}
                    omitDeletionAnimation={true}
                    style={{ fontSize: '1em', display: 'block', minHeight: '200px', whiteSpace: 'pre-wrap'}}
                />
            </Card>
        </Space>
    );
}

const mapStateToProps = state => {
    return {
        result: state.resultReducer.result
    }
}

export default connect(mapStateToProps)(AnaliseResult);