import React from 'react';
import './Description.css';


const Description = () => {
    return (
        <div class='descriptionContainer'>
            <h2>Análise de redação grátis</h2>
            <p>
                A análise de redação grátis é uma ferramenta que ajuda você a melhorar a qualidade do seu texto.
                Através de um algoritmo inteligente de correção de redação, você pode receber um feedback instantâneo sobre o seu texto.
            </p>
            <p>
                Nosso serviço de correção online identifica e sugere correções para uma ampla gama de erros, incluindo gramática, pontuação, estilo e coesão. Esta ferramenta é especialmente útil para estudantes, escritores e profissionais que desejam aprimorar suas habilidades de escrita, assegurando que suas obras sejam não apenas corretas, mas também envolventes e bem articuladas.
            </p>
            <h2>Como Funciona?</h2>
            <p>
                Para utilizar nossa ferramenta de correção de redação grátis, simplesmente carregue seu texto no sistema ou digite diretamente em nossa interface. Em segundos, o algoritmo analisa seu texto e fornece sugestões detalhadas de melhoria. Cada sugestão é acompanhada de explicações claras, ajudando você a entender o porquê de cada correção e como você pode evitar erros semelhantes no futuro.
            </p>
            <h2>Benefícios da Correção Automatizada</h2>
            <p>
                A correção automatizada de redações oferece vários benefícios. Primeiramente, ela é extremamente rápida, permitindo que você receba feedback imediato. Além disso, é uma ferramenta imparcial e consistente, garantindo que todos os textos sejam avaliados segundo os mesmos padrões de qualidade. Com a prática regular, você pode melhorar significativamente suas habilidades de escrita, aumentando suas chances de sucesso acadêmico e profissional.
            </p>
            <h2>Por que Escolher Nossa Ferramenta?</h2>
            <p>
                Optar pela nossa ferramenta de análise de redação grátis significa escolher um caminho de aprendizado contínuo e desenvolvimento. Nossa plataforma utiliza tecnologia de ponta para fornecer correções precisas e dicas de aprimoramento que são específicas para as necessidades de cada usuário. Além disso, ao integrar nossa ferramenta em sua rotina diária, você ganha a capacidade de escrever com mais confiança e precisão, sabendo que tem o suporte necessário para entregar textos de alta qualidade.
            </p>
        </div>
    );
};

export default Description;
