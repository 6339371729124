import React from 'react';
import {FilePdfTwoTone, FileWordTwoTone} from '@ant-design/icons';
import {Upload} from 'antd';
import {message} from 'antd';
import axios from 'axios';

import './Upload.css';

const {Dragger} = Upload;

const UploadComponent = ({setUploadSuccess, setTaskId}) => {
    const props = {
        name: 'file',
        multiple: false,
        customRequest: async ({file, onSuccess, onError}) => {
            try {

                const apiBaseUrl = process.env.REACT_APP_BASE_API_URL;
                const apiUrl = `${apiBaseUrl}/generate-presigned-url`;
                const response = await axios({
                    method: 'POST',
                    url: apiUrl,
                    data: {
                        filename: file.name
                    }
                });

                const presignedUrl = response.data.url;
                await axios.put(presignedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                    }
                });
                console.log(`${file.name} file uploaded successfully.`);
                setUploadSuccess(true);
                onSuccess();
                const taskId = response.data.task_id;
                setTaskId(taskId);
            } catch (error) {
                console.error(`${file.name} file upload failed.`);
                setUploadSuccess(false);
                onError(error);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const beforeUpload = (file) => {
        const isPdf = file.type === 'application/pdf';
        if (!isPdf) {
            message.error('Você só pode fazer upload de arquivos PDF!');
        }
        return isPdf;
    }

    return (
        <div className="upload-container">
            <Dragger {...props} beforeUpload={beforeUpload}>
                <p className="ant-upload-drag-icon">
                    <FilePdfTwoTone/>
                </p>
                <p className="ant-upload-text upload-escolher">ESCOLHER ARQUIVOS</p>
                <p className="ant-upload-hint">
                    Extensões suportadas: .pdf
                </p>
            </Dragger>
        </div>
    );
}

export default UploadComponent;