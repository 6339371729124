import React, { useState } from 'react';
import UploadComponent from "../components/Upload";
import './Home.css';
import StepsAnalise from "../components/StepsAnalise";
import Description from '../components/Description';

const Home = () => {
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [taskId, setTaskId] = useState(null);

    return (
        <div className="App">
            <h1 className="title">Redação Grátis - Correção e análise de redação online</h1>
            <UploadComponent setUploadSuccess={setUploadSuccess} setTaskId={setTaskId} />
            <div style={{ display: uploadSuccess ? 'block' : 'none' }}>
                <StepsAnalise taskId={taskId} uploadSuccess={uploadSuccess} />
            </div>
            <Description />
        </div>
    );
}

export default Home;