// reducers/resultReducer.js
const resultReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_RESULT':
            return action.payload;
        default:
            return state;
    }
};

export default resultReducer;